let agencias = [
    {
        id:473,
        name: 'Centro',
    },
    {
        id: 466,
        name: 'Scharlau',
    },
    {
        id: 3353,
        name: 'Unisinos',
    },
    {
        id: 4413,
        name: 'Rio Branco',
    },
    {
        id: 4471,
        name: 'Feitoria',
    },
]
export {agencias}
