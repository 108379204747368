import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="header">
          <div className="titulo">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria <br />
              <strong>
                com soluções para <br />
                sua empresa
              </strong>
            </h2>
          </div>
          <div className="descricao">
            <p>
              O Sebrae está oferecendo 2 capacitações online{' '}
              <strong>GRATUITAS</strong> aos empreendedores interessados.
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
        </div>

        <div className="content">
          <div className="col">
            <h3>Capacitação GRATUITA em Marketing e Vendas</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/marketing-digital-direto-ao-ponto/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Venda mais com os canais digitais
            </a>

            <h3>Capacitação GRATUITA em Finanças</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/financas/curso/programa-sao-leo-mais-credito/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impulsione suas Finanças
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
